import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { List, ListItem, ListItemIcon, ListItemText, useDocumentTitle } from '@clatter/ui';
import {
  featureFlagsMap,
  hasActiveFeatureFlag,
  platformSelectors,
  useAuth,
  userRolesMap,
  hasRole,
} from '@clatter/platform';
import {
  ViewComfyOutlined as ViewCompactOutlinedIcon,
  ListOutlined as ListOutlinedIcon,
  PanoramaOutlined as PanoramaOutlinedIcon,
  AccountBoxOutlined as AccountBoxOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  OndemandVideoOutlined as OndemandVideoOutlinedIcon,
  AnalyticsOutlined as AnalyticsIcon,
} from '@mui/icons-material';
import Page from '../Page/Page';
import Bios from './Bios/Bios';
import BioGroups from './BioGroups/BioGroups';
import Resources from './Resources/Resources';
import ResourceCategories from './ResourceCategories/ResourceCategories';
import HeroImages from './HeroImages/HeroImages';
import VideoCategories from './VideoCategories/VideoCategories';
import VideoEmbeds from './VideoEmbeds/VideoEmbeds';
import MicrositesSettings from './MicrositesSettings/MicrositesSettings';
import LandingPage from './LandingPage/LandingPage';
import Templates from './Templates/Templates';
import TrackingPixel from './TrackingPixel/TrackingPixel';
import CoBrandLogos from './CoBrandLogos/CoBrandLogos';
import { documentTitleMap } from '../../routes/routes';

const StyledAdmin = styled.div`
  display: grid;
  grid-template-areas: 'sidebar content';
  grid-template-columns: 250px 1fr;
  grid-auto-rows: auto;
  align-items: start;
  gap: 24px;
  height: 100%;

  .admin-nav {
    position: sticky;
    top: 80px;
  }

  .admin-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > *:first-child + * {
      flex: 1;
      height: calc(100% - 82px);
    }
  }
`;

const Admin = () => {
  const featureFlags = useSelector(platformSelectors.featureFlag.selectAllFeatureFlags);
  const location = useLocation();
  const { activeUser } = useAuth();
  const isTrackingPixelEnabled =
    hasActiveFeatureFlag({
      flagName: featureFlagsMap.MSM.trackingPixel,
      featureFlags: featureFlags,
    }) && hasRole(userRolesMap.systemAdmin, activeUser);
  const isMsmAdmin = hasRole(userRolesMap.msmAdmin, activeUser);

  if (location?.pathname === '/admin') {
    useDocumentTitle(documentTitleMap.admin);
  }

  return (
    <Page>
      <StyledAdmin>
        <div className="admin-nav">
          <List>
            <ListItem hoverable component={NavLink} componentProps={{ to: 'bios' }}>
              <ListItemIcon>
                <AccountBoxOutlinedIcon />
              </ListItemIcon>
              <ListItemText text="Bios" />
            </ListItem>
            <ListItem hoverable component={NavLink} componentProps={{ to: 'bio-groups' }}>
              <ListItemIcon>
                <ListOutlinedIcon />
              </ListItemIcon>
              <ListItemText text="Bio groups" />
            </ListItem>

            {isMsmAdmin && (
              <ListItem hoverable component={NavLink} componentProps={{ to: 'landing-page' }}>
                <ListItemIcon>
                  <ListOutlinedIcon />
                </ListItemIcon>
                <ListItemText text="Landing Page" />
              </ListItem>
            )}

            <ListItem hoverable component={NavLink} componentProps={{ to: 'resources' }}>
              <ListItemIcon>
                <DescriptionOutlinedIcon />
              </ListItemIcon>
              <ListItemText text="Resources" />
            </ListItem>
            <ListItem hoverable component={NavLink} componentProps={{ to: 'resource-categories' }}>
              <ListItemIcon>
                <ListOutlinedIcon />
              </ListItemIcon>
              <ListItemText text="Resource categories" />
            </ListItem>
            <ListItem hoverable component={NavLink} componentProps={{ to: 'videos' }}>
              <ListItemIcon>
                <OndemandVideoOutlinedIcon />
              </ListItemIcon>
              <ListItemText text="Videos" />
            </ListItem>
            <ListItem hoverable component={NavLink} componentProps={{ to: 'video-categories' }}>
              <ListItemIcon>
                <ListOutlinedIcon />
              </ListItemIcon>
              <ListItemText text="Video categories" />
            </ListItem>
            <ListItem hoverable component={NavLink} componentProps={{ to: 'hero-images' }}>
              <ListItemIcon>
                <PanoramaOutlinedIcon />
              </ListItemIcon>
              <ListItemText text="Hero images" />
            </ListItem>
            <ListItem hoverable component={NavLink} componentProps={{ to: 'co-brand-logos' }}>
              <ListItemIcon>
                <PanoramaOutlinedIcon />
              </ListItemIcon>
              <ListItemText text="Co Brand Logos" />
            </ListItem>

            {isMsmAdmin && (
              <ListItem hoverable component={NavLink} componentProps={{ to: 'templates' }}>
                <ListItemIcon>
                  <ViewCompactOutlinedIcon />
                </ListItemIcon>
                <ListItemText text="Templates" />
              </ListItem>
            )}

            {isTrackingPixelEnabled && (
              <ListItem hoverable component={NavLink} componentProps={{ to: 'tracking-pixel' }}>
                <ListItemIcon>
                  <AnalyticsIcon />
                </ListItemIcon>
                <ListItemText text="Tracking Pixel" />
              </ListItem>
            )}
          </List>
        </div>

        <div className="admin-content">
          <Routes>
            <Route index element={<MicrositesSettings />} />

            <Route path="bios" element={<Bios />} />

            <Route path="bio-groups" element={<BioGroups />} />

            <Route path="landing-page" element={<LandingPage />} />

            <Route path="resources" element={<Resources />} />

            <Route path="resource-categories" element={<ResourceCategories />} />

            <Route path="videos" element={<VideoEmbeds />} />

            <Route path="video-categories" element={<VideoCategories />} />

            <Route path="hero-images" element={<HeroImages />} />

            <Route path="co-brand-logos" element={<CoBrandLogos />} />

            <Route path="templates" element={<Templates />} />

            {isTrackingPixelEnabled && <Route path="tracking-pixel" element={<TrackingPixel />} />}
          </Routes>
        </div>
      </StyledAdmin>
    </Page>
  );
};

export default Admin;
