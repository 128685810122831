import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Stack } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import {
  Button,
  Dialog,
  Loader,
  DataTable,
  IconButton,
  ExportImport,
  VideoPreviewDialog,
  useDocumentTitle,
  usePageTitleHeader,
} from '@clatter/ui';
import { formatDateTime, useDeepCompareMemo, useNotices } from '@clatter/platform';
import VideoEmbedForm from './VideoEmbedForm';
import {
  deleteVideoEmbed,
  fetchVideoEmbeds,
  selectAllVideoEmbeds,
} from '../../../store/video-embeds.slice';
import routes, { documentTitleMap } from '../../../routes/routes';

const videoCategoryColumns = [
  {
    label: 'Title',
    name: 'title',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Category',
    name: 'video_category',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Created by',
    name: 'c_created_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDateTime(row.createdAt),
    align: 'left',
    sortable: true,
  },
  {
    label: 'Updated by',
    name: 'c_updated_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date updated',
    name: 'updatedAt',
    customRender: (row) => formatDateTime(row?.updatedAt) || '',
    align: 'left',
    sortable: true,
  },
];

const VideoEmbeds = () => {
  useDocumentTitle(documentTitleMap.adminVideos)
  const dispatch = useDispatch();
  const { addNotice } = useNotices();
  const [editableItem, setEditableItem] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: "Videos",
    linksType: 'msm-admin',
    routes: routes,
  });

  useEffect(() => {
    dispatch(fetchVideoEmbeds());
  }, [dispatch]);

  const handleImportSuccess = (response) => {
    dispatch(fetchVideoEmbeds());

    const message = [];
    if (response.data?.imported?.length > 0) {
      message.push(
        `Successfully imported ${response.data?.imported?.length} video(s).`,
      );
    }
    if (response.data?.omitted?.length > 0) {
      message.push(
        `${response.data?.omitted?.length} video(s) omitted (duplicated link).`,
      );
    }

    addNotice({
      message: message.join(' '),
      type: 'success',
      title: 'Success',
    });
  };

  const videoEmbeds = useSelector(selectAllVideoEmbeds);

  const loading = useSelector(
    (state) => state.videoEmbeds.loadingStatus === 'loading',
  );

  const mappedVideoEmbeds = useDeepCompareMemo(() =>  videoEmbeds.map(item => ({
    ...item,
    video_category: item?.video_category?.categoryName,
  })), [videoEmbeds]);

  const handleEditClick = (event) => {
    const clickedItemId = parseInt(event.currentTarget?.dataset?.id);

    setEditableItem(
      (clickedItemId &&
        videoEmbeds.find(
          (videoCategory) => videoCategory.id === clickedItemId,
        )) ||
      {},
    );
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this video?')) {
      dispatch(deleteVideoEmbed(parseInt(event.currentTarget.dataset.id)));
    }
  };

  const handlePreviewClick = (event) => {
    const previewVideo = videoEmbeds.find(
      (item) => item.id === parseInt(event.currentTarget.dataset.id),
    );

    setPreviewVideo(previewVideo);
  };

  const handleClosePreviewDialog = () => {
    setPreviewVideo(null);
  };

  const isEdit = editableItem && 'link' in editableItem;

  return (
    <>
      {loading && <Loader />}

      { renderPageTitleHeader({
        headerActions:
          <Stack spacing={1} direction="row" alignItems="center">
            <ExportImport
              exportFileName={(() =>
                `videos_${process.env.NX_CLIENT}_${format(
                  new Date(),
                  'yyyy-MM-dd_HH-mm-ss',
                )}`)()}
              exportUrl="/videos"
              exportDisabled={!mappedVideoEmbeds || mappedVideoEmbeds?.length === 0}
              onImportSuccessCallback={handleImportSuccess}
            />
            <Button onClick={handleEditClick}>Add video embed</Button>
          </Stack>
        })
      }

      <div>
        <DataTable
          autofit
          columns={videoCategoryColumns}
          defaultSortField="title"
          filterColumns={['title', 'video_category']}
          name="adminVideos"
          rows={mappedVideoEmbeds}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handlePreviewClick}>
                <VisibilityOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <VideoPreviewDialog
        title={previewVideo?.title}
        videoUrl={previewVideo?.link}
        open={previewVideo?.link}
        onCloseDialog={handleClosePreviewDialog}
      />
      <Dialog
        title={isEdit ? 'Edit video embed' : 'Add video embed'}
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <VideoEmbedForm
          isEdit={isEdit}
          editableItem={editableItem}
          onSuccess={handleDismissEdit}
        />
      </Dialog>
    </>
  );
};

export default VideoEmbeds;
