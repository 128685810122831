import React, { useEffect } from 'react';
import { useParams, generatePath, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { msmPageTemplatesMap, useAuth } from '@clatter/platform';
import {
  fetchMicrosites,
  fetchPageTemplates,
  fetchPages,
  updatePage,
  fetchVideoEmbeds,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import {
  Button,
  FormControl,
  Loader,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  usePageTitleHeader,
} from '@clatter/ui';
import Page from "../../components/Page/Page";
import VideoPicker from '../../components/VideoPicker/VideoPicker';
import { pageFromStore } from '../fromStore';
import { videoTableColumns } from '../ComponentsList/mocks';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import { getNextPage, isSiteComplete } from '../../helpers';
import SiteMakerActions from '../../components/SiteMaker/SiteMakerActions';
import PreviewButton from '../../components/SiteMaker/PreviewButton';
import { selectAllVideoEmbeds } from '../../store/video-embeds.slice';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import routes from '../../routes/routes';
import useMsmPageTitle from "../../hooks/useMsmPageTitle";
import { selectPagesEntities } from '../../store/pages.slice';

const VideosBlockConfig = () => {
  // set page title for GA4
  useMsmPageTitle();

  const navigate = useNavigate();
  const { user, isLoading } = useAuth();
  let { siteId, pageId } = useParams();

  // parse params
  pageId = parseInt(pageId);

  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await dispatch(
        updatePage({
          id: currentPage.id,
          ...formData,
        }),
      );
    }
    navigate(getNextPage(currentMicrosite, currentMicrosite.pages));
  };

  const onPreviewClick = async () => {
    const formData = getValues();
    await dispatch(
      updatePage({
        id: currentPage.id,
        ...formData,
      }),
    );
    // reset form "dirty" state so further "preview"
    // clicks will not trigger the page update
    reset(formData);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    // not site/page dependent fetches
    dispatch(fetchPageTemplates());
    dispatch(fetchVideoEmbeds());

    if (!siteId) {
      return false;
    }

    (async () => {
      const microsites = await dispatch(fetchMicrosites({ user: user, micrositeId: siteId }));
      const micrositePagesIds = (microsites?.payload?.data?.[0]?.attributes?.pages?.data || []).map(page => page?.id);

      dispatch(fetchPages({ pagesIds: micrositePagesIds }));
    })();

    window.scrollTo(0, 0)
  }, [siteId, user]);

  const videosLoading = useSelector((state) => state.videoEmbeds.loadingStatus);
  const pageTemplatesLoading = useSelector(
    (state) => state.pageTemplates.loadingStatus,
  );
  const micrositesLoading = useSelector(
    (state) => state.microsites.loadingStatus,
  );
  const pagesLoading = useSelector((state) => state.pages.loadingStatus);

  const loading =
    videosLoading === 'loading' ||
    pageTemplatesLoading === 'loading' ||
    micrositesLoading === 'loading' ||
    pagesLoading === 'loading';

  const pageTemplates = useSelector(selectAllPageTemplates);

  const videos = useSelector(selectAllVideoEmbeds);

  const pageEntities = useSelector(selectPagesEntities);

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(siteId, page, pageTemplates, pageEntities[page.id]),
      ),
    };
  });

  const currentPage = pageEntities[pageId] || null;

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentPage: currentPage,
    currentMicrosite: currentMicrosite,
    pageTitle: "Select Your Videos",
    routes: routes,
  });

  useEffect(() => {
    // update form selected videos only once (after setting them in currentPage)
    if (Array.isArray(currentPage?.video_embeds)) {
      setValue('video_embeds', currentPage?.video_embeds);
    }
  }, [!!currentPage?.video_embeds && currentPage.video_embeds.length !== 0]);

  const redirectToPublish = () => {
    navigate(
      generatePath(routes.publishSite, { siteId: currentMicrosite.id }),
    );
  };

  const templateVideosRange = {
    'Additional Resources with videos': {
      min: 0,
      max: 5,
    },
    'Additional Resources with videos - Optum Rx brand': {
      min: 0,
      max: 12,
    },
    'Additional Resources with videos - Optum brand': {
      min: 0,
      max: 5,
    },
    [msmPageTemplatesMap.ADDITIONAL_RESOURCES_WITH_VIDEOS_OPTUM_FINANCIAL]: {
      min: 0,
      max: 5,
    },
    [msmPageTemplatesMap.UHC_MAIN]: {
      min: 3,
    },
    [msmPageTemplatesMap.URS_MAIN_MA]: {
      min: 3,
    },
  };

  const getVideosRange = (templateName) =>
    templateVideosRange[templateName] || { min: 3, max: 6 };

  const getSubhead = () => {
    const videosRange = getVideosRange(currentPage?.templateName);

    const subheadText =
      'Add {range} to the content section of your page. A preview of your selected videos will appear below.';

    if (videosRange.min && videosRange.max) {
      return subheadText.replace(
        '{range}',
        `between ${videosRange.min} to ${videosRange.max}`,
      );
    }

    if (videosRange.min) {
      return subheadText.replace(
        '{range}',
        `${videosRange.min} or more ${
          videosRange.min === 1 ? 'video' : 'videos'
        }`,
      );
    }

    if (videosRange.max) {
      return subheadText.replace(
        '{range}',
        `up to ${videosRange.max} ${
          videosRange.max === 1 ? 'video' : 'videos'
        }`,
      );
    }

    return subheadText.replace('{range} ', '');
  };

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Videos and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={isDirty && !isValid ? null : currentMicrosite?.name}
        pageName={currentPage?.name}
        onPreviewClick={isDirty ? onPreviewClick : true}
      />
      <Button
        disabled={isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  // wait for currentPage being set before showing UI
  if (!currentPage?.video_embeds) {
    return <Loader />
  }

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  return (
    <Page key={pageId}>
      {loading && <Loader />}

      <SiteMaker site={currentMicrosite}>

        { renderPageTitleHeader() }

        <SiteMakerContentHead
          subhead={getSubhead()}
          pageId={pageId}
          siteName={currentMicrosite?.name}
          pageName={currentPage?.name}
        />

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <StepCard>
            <StepCardHeader step={1}>
              <StepCardTitle text="Select videos" />
            </StepCardHeader>
            <StepCardContent>
              <FormControl noPadding error={errors.video_embeds}>
                <Controller
                  name="video_embeds"
                  rules={{
                    validate: (value) => {
                      const itemsCount = value?.length;

                      if (!itemsCount) {
                        return 'Field is required';
                      }

                      const videosRange = getVideosRange(
                        currentPage?.templateName,
                      );

                      if (videosRange.min && itemsCount < videosRange.min) {
                        return `Pick at least ${videosRange.min} ${
                          videosRange.min === 1 ? 'video' : 'videos'
                        }`;
                      }

                      if (videosRange.max && itemsCount > videosRange.max) {
                        return `Pick up to ${videosRange.min} ${
                          videosRange.min === 1 ? 'video' : 'videos'
                        }`;
                      }

                      return true;
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <VideoPicker
                      max={getVideosRange(currentPage?.templateName).max}
                      onChange={onChange}
                      options={videos}
                      tableColumns={videoTableColumns}
                      value={value}
                    />
                  )}
                  control={control}
                  defaultValue={currentPage?.video_embeds}
                />
              </FormControl>
            </StepCardContent>
          </StepCard>
          <SiteMakerActions renderButtons={renderButtons} />
        </form>
      </SiteMaker>
    </Page>
  );
};

export default VideosBlockConfig;
