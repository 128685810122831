import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import {
  fetchMicrosites,
  fetchPageTemplates,
  fetchPages,
  fetchResources,
  fetchHeroImages,
  fetchVideoEmbeds,
} from '../../store';

import { pageFromStore } from '../fromStore';

import {
  AdditionalVideos,
  PartnerSite,
  Resources,
  ProspectSite,
} from '@clatter/templates';
import { Button, LandingPage } from '@clatter/ui';
import { msmPageTemplatesMap } from "@clatter/platform";
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import Page from "../../components/Page/Page";

const StyledPagePreview = styled.div`
  font-family: Arial, sans-serif;

  .action-bar {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    background-color: #fff;
    height: 64px;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;

    + div {
      > header {
        top: 64px;
      }
    }
  }
`;

// the static pages published by Gatsby are simply
// our PageTemplate components, with all their props supplied
// for true publication of static pages, the gatsby build process
// instantiates components for each page, then renders them and
// emits the html and related bundle

// for Preview, we need simply to return an instance of the
// template, with props supplied from the current Page
const PagePreview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pageId } = useParams();

  // parse params
  pageId = parseInt(pageId);

  useEffect(() => {
    dispatch(fetchVideoEmbeds());
    dispatch(fetchPages());
    dispatch(fetchMicrosites({ user: null, fetchAll: true }));
    dispatch(fetchPageTemplates());
    dispatch(fetchHeroImages());
    dispatch(fetchResources());
  }, [dispatch]);

  const videosLoading = useSelector((state) => state.videoEmbeds.loadingStatus);
  const heroesLoading = useSelector((state) => state.heroImages.loadingStatus);
  const resourcesLoading = useSelector(
    (state) => state.resources.loadingStatus,
  );
  const pageTemplatesLoading = useSelector(
    (state) => state.pageTemplates.loadingStatus,
  );
  const micrositesLoading = useSelector(
    (state) => state.microsites.loadingStatus,
  );
  const pagesLoading = useSelector((state) => state.pages.loadingStatus);

  const loading =
    videosLoading === 'loading' ||
    heroesLoading === 'loading' ||
    resourcesLoading === 'loading' ||
    pageTemplatesLoading === 'loading' ||
    micrositesLoading === 'loading' ||
    pagesLoading === 'loading';

  const microsites = useSelector((state) =>
    Object.values(state.microsites.entities),
  );

  const pageTemplates = useSelector(selectAllPageTemplates);

  const pages = useSelector((state) =>
    Object.values(state.pages.entities).map((p) =>
      pageFromStore(p, pageTemplates),
    ),
  );

  const videoEmbeds = useSelector((state) =>
    Object.values(state.videoEmbeds.entities),
  );

  const [currentMicrosite, setCurrentMicrosite] = React.useState(null);
  const [currentPages, setCurrentPages] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(null);

  useEffect(() => {
    if (microsites.length < 1 || pages.length < 1 || pageTemplates.length < 1) {
      return;
    }

    const nextPage = pageFromStore(
      pages.find((page) => page.id === pageId),
      pageTemplates,
    );

    const nextMicrosite = microsites.find(
      (microsite) =>
        microsite.pages.find((page) => page.id === pageId) !== undefined,
    );

    if (!currentMicrosite || currentMicrosite.id !== nextMicrosite.id) {
      setCurrentPages(null);
      setCurrentMicrosite(nextMicrosite);
    }

    if (!currentPage) {
      setCurrentPage(nextPage);
    }
  }, [pageId, microsites, pages, pageTemplates]);

  useEffect(() => {
    if (!currentMicrosite || pageTemplates.length < 1) {
      return;
    }

    const nextPages = currentMicrosite.pages.map((page) =>
      pageFromStore(page, pageTemplates),
    );

    if (!currentPages) {
      setCurrentPages(nextPages);
    }
  }, [currentMicrosite, pageTemplates]);

  if (loading || !currentPage) {
    return <div>loading...</div>;
  }

  const data = {
    microsites: {
      edges: microsites.map((ms) => ({ node: ms })),
    },
    pages: {
      edges: [
        {
          node: {
            ...currentPage,
            videos: videoEmbeds,
          },
        },
      ],
    },
  };

  const handleCloseClick = () => {
    navigate(-1);
  };

  const renderPage = (page, data) => {
    switch (page.page_template.title) {
      case 'Partner Site':
        return <PartnerSite data={data} />;

      case 'Additional Resources':
        return <Resources data={data} />;

      case 'Prospect Site':
      case 'Prospect Landing Page':
        return <ProspectSite data={data} />;

      case 'Additional Videos':
      case 'Video Template':
      case msmPageTemplatesMap.VIDEO_TEMPLATE_ADMIN_OPTUM:
        return <AdditionalVideos data={data} />;

      case 'Landing Page':
        return <LandingPage data={data} />;

      default:
        return (
          <div>
            ERROR: template not found.<pre>{JSON.stringify(page, null, 2)}</pre>
          </div>
        );
    }
  };

  return (
    <Page clean>
      <StyledPagePreview>
        <div className="action-bar">
          <Button icon={CloseIcon} onClick={handleCloseClick}>
            Close preview
          </Button>
        </div>
        {renderPage(currentPage, data)}
      </StyledPagePreview>
    </Page>
  );
};

export default PagePreview;
