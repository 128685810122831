import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { msmPageTemplatesMap, useAuth } from '@clatter/platform';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
  fetchMicrosites,
  fetchPageTemplates,
  fetchPages,
  updatePage,
  fetchAllResources,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import {
  Dialog,
  Button,
  StepCard,
  StepCardHeader,
  StepCardTitle,
  StepCardAction,
  StepCardContent,
  FormControl,
  Loader,
  Checkbox,
  TextControl,
  usePageTitleHeader,
} from '@clatter/ui';
import ResourcePicker from '../../components/ResourcePicker/ResourcePicker';
import {
  pageFromStore,
  resourceFromStore,
  resourceCategoryFromStore,
} from '../fromStore';
import Page from "../../components/Page/Page";
import { resourcesTableColumns } from '../ComponentsList/mocks';
import ResourceForm from '../../components/Admin/Resources/ResourceForm';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import { getNextPage, isSiteComplete} from '../../helpers';
import SiteMakerActions from '../../components/SiteMaker/SiteMakerActions';
import PreviewButton from '../../components/SiteMaker/PreviewButton';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import routes from '../../routes/routes';
import useMsmPageTitle from "../../hooks/useMsmPageTitle";
import { selectPagesEntities } from '../../store/pages.slice';

// the problem we have with our current pattern of selectors is the combination of:
// 1. we refer to the microsites selector to get current pages. this is dependent on
//    the microsites slice being updated whenever a page is updated.
// 2. we rely on the pages selector subscription to force re-render when the pages
//    slice is updated, but the page slice update doesn't change the microsites slice
// effectively, whenever we update a page, the microsites site entry is stale and should
// be refreshed. but it isn't. we rely on an effect (post - render!) to dispatch a new
// fetch of the microsites, but these function components see the stale page value in
// the microsites entry.
//
// the core *requirement* is that the *current* page is guaranteed fresh
// this requires a subscription to the pages slice - specifically, to the current page
// element of that slice. so, we introduced a currentPage selector to subscribe
// specifically to that element.
//
// for the context rail, the requirement is that the currentPages array is fresh

export const renderPreviewTypes = {
  simple: 'simple',
  table: 'table',
};

const ResourcesBlockConfig = () => {
  // set page title for GA4
  useMsmPageTitle();

  const navigate = useNavigate();
  const { user, isLoading } = useAuth();
  const [showResourcesDialog, setShowResourcesDialog] = useState(false);
  let { siteId, pageId } = useParams();

  // parse params
  pageId = parseInt(pageId);

  const toggleShowResourcesDialog = () => {
    setShowResourcesDialog(!showResourcesDialog);
  };

  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    watch,
    register,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await dispatch(
        updatePage({
          id: currentPage.id,
          ...formData,
        }),
      );
    }
    navigate(getNextPage(currentMicrosite, currentMicrosite.pages));
  };

  const onPreviewClick = async () => {
    const formData = getValues();
    await dispatch(
      updatePage({
        id: currentPage.id,
        ...formData,
      }),
    );
    // reset form "dirty" state so further "preview"
    // clicks will not trigger the page update
    reset(formData);
  };

  // while this is *necessary* in that if a page is being rendered
  // without anything in the store, this effect dispatches all the
  // necessary api calls, what about the case when everything is
  // fresh? this dispatches all requests even if store is fresh.
  const dispatch = useDispatch();

  useEffect(() => {
    // not site/page dependent fetches
    dispatch(fetchPageTemplates());
    dispatch(fetchAllResources());

    if (!siteId) {
      return false;
    }

    (async () => {
      const microsites = await dispatch(fetchMicrosites({ user: user, micrositeId: siteId }));
      const micrositePagesIds = (microsites?.payload?.data?.[0]?.attributes?.pages?.data || []).map(page => page?.id);

      dispatch(fetchPages({ pagesIds: micrositePagesIds }));
    })();

    window.scrollTo(0, 0)
  }, [siteId, user]);

  const loading = useSelector(
    (state) =>
      state.pages.loadingStatus === 'loading' ||
      state.pageTemplates.loadingStatus === 'loading' ||
      state.microsites.loadingStatus === 'loading' ||
      state.resources.loadingStatus === 'loading',
  );

  const pageTemplates = useSelector(selectAllPageTemplates);

  const resources = useSelector((state) =>
    Object.values(state.resources.entities).map(resourceFromStore),
  );

  const pageEntities = useSelector(selectPagesEntities);

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(siteId, page, pageTemplates, pageEntities[page.id]),
      ),
    };
  });

  const currentPage = pageEntities[pageId] || null;

  const isCTAButtonEnabled = watch('resources_call_to_action_button_display');
  const enableOptionalCTAButton = [
    msmPageTemplatesMap.UHC_MAIN,
    msmPageTemplatesMap.URS_MAIN_MA,
    msmPageTemplatesMap.LANDING_PAGE_MA,
    msmPageTemplatesMap.LANDING_PAGE_UHC,
  ].includes(currentPage?.templateName);

  const headerText = (() => {
    if (currentPage?.templateName === 'Landing Page') {
      return 'Select Internal Resources';
    }

    return 'Select Featured Resources';
  })();

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentPage: currentPage,
    currentMicrosite: currentMicrosite,
    pageTitle: headerText,
    routes: routes,
  });

  useEffect(() => {
    if (currentPage && enableOptionalCTAButton) {
      setValue('resources_call_to_action_button_display', currentPage?.resources_call_to_action_button_display || false)
      setValue('resources_call_to_action_button_label', currentPage?.resources_call_to_action_button_label || '')
      setValue('resources_call_to_action_button_url', currentPage?.resources_call_to_action_button_url || '')
    }
  }, [
    currentPage?.resources_call_to_action_button_display,
    currentPage?.resources_call_to_action_button_label,
    currentPage?.resources_call_to_action_button_url,
    enableOptionalCTAButton
  ]);

  useEffect(() => {
    // update form selected resources only once (after setting them in currentPage)
    if (Array.isArray(currentPage?.resources)) {
      setValue('resources', currentPage.resources);
    }
  }, [!!currentPage?.resources && currentPage.resources.length !== 0]);

  const resourceCategories = useSelector((state) =>
    state.resources.categories
      .map(resourceCategoryFromStore)
      .map(({ id, categoryName }) => ({
        value: id,
        label: categoryName,
      })),
  );

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  // wait for currentPage being set before showing UI
  if (!currentPage?.resources) {
    return <Loader />
  }

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  const templateResourcesRange = {
    'Prospect Site': {
      min: 0,
      max: 6,
    },
    'Prospect Site UHC ': {
      min: 0,
      max: 4,
    },
    'Prospect Site UHC - Optum': {
      min: 0,
      max: 4,
    },
    [msmPageTemplatesMap.UHC_MAIN]: {
      min: 0,
      max: 8,
    },
    [msmPageTemplatesMap.URS_MAIN_MA]: {
      min: 0,
      max: 8,
    },
    [msmPageTemplatesMap.PROSPECT_SITE_UHC_UMR]: {
      min: 0,
      max: 4,
    },
    'Prospect Site - Coley': {
      min: 0,
      max: 4,
    },
  };

  const getResourcesRange = (templateName) =>
    templateResourcesRange[templateName] || { min: 0, max: 0 };
  const getSubhead = () => {
    const resourcesRange = getResourcesRange(currentPage?.templateName);

    const subheadText =
      'Upload {range} resources from your computer, and/or select other available resources. A preview of your featured resources will appear below.';

    if (resourcesRange.min && resourcesRange.max) {
      return subheadText.replace(
        '{range}',
        `between ${resourcesRange.min} to ${resourcesRange.max}`,
      );
    }

    if (resourcesRange.min) {
      return subheadText.replace(
        '{range}',
        `at least ${resourcesRange.min} ${
          resourcesRange.min === 1 ? 'resource' : 'resources'
        }`,
      );
    }

    if (resourcesRange.max) {
      return subheadText.replace(
        '{range}',
        `up to ${resourcesRange.max} ${
          resourcesRange.max === 1 ? 'resource' : 'resources'
        }`,
      );
    }

    return subheadText.replace('{range} ', '');
  };

  const redirectToPublish = () => {
    navigate(
      generatePath(routes.publishSite, { siteId: currentMicrosite.id }),
    );
  };

  const previewBackgroundMapping = {
    'Additional Resources': '#f4f7fc',
    'Partner Site': '#f4f7fc',
    'Prospect Site': '#f4f7fc',
  };

  const previewHeaderMapping = {
    'Prospect Site UHC': 'Resources',
  };

  const previewTitleMapping = {
    'Prospect Site - Coley': 'Your resources',
    'Prospect Site': 'Your resources',
  };

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Resources and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite?.name}
        pageName={currentPage?.name}
        onPreviewClick={isDirty ? onPreviewClick : true}
      />
      <Button
        disabled={isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <Page key={pageId}>
      {loading && <Loader />}
      <SiteMaker site={currentMicrosite}>

        { renderPageTitleHeader() }

        <SiteMakerContentHead subhead={getSubhead()} />

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <StepCard>
            <StepCardHeader step={1}>
              <StepCardTitle text="Add available resources" />
              <StepCardAction
                label="Add new resource"
                icon={AddOutlinedIcon}
                onClick={toggleShowResourcesDialog}
              />
            </StepCardHeader>
            <StepCardContent>
              <FormControl error={errors.resources}>
                <Controller
                  rules={{
                    validate: (value) => {
                      const itemsCount = value?.length;

                      if (!itemsCount) {
                        return 'Field is required';
                      }

                      const resourcesRange = getResourcesRange(
                        currentPage?.templateName,
                      );

                      if (
                        resourcesRange.min &&
                        itemsCount < resourcesRange.min
                      ) {
                        return `Pick at least ${resourcesRange.min} ${
                          resourcesRange.min === 1 ? 'resource' : 'resources'
                        }`;
                      }

                      if (
                        resourcesRange.max &&
                        itemsCount > resourcesRange.max
                      ) {
                        return `Pick up to ${resourcesRange.min} ${
                          resourcesRange.min === 1 ? 'resource' : 'resources'
                        }`;
                      }
                    },
                  }}
                  name="resources"
                  render={({ field: { onChange, value } }) => (
                    <ResourcePicker
                      max={ getResourcesRange(currentPage?.templateName)?.max || 0}
                      onChange={onChange}
                      options={resources}
                      tableColumns={resourcesTableColumns}
                      value={value}
                    />
                  )}
                  control={control}
                  defaultValue={currentPage?.resources}
                />
              </FormControl>
            </StepCardContent>
          </StepCard>

          { enableOptionalCTAButton &&
              <StepCard>
                <StepCardHeader step={2}>
                  <StepCardTitle text="Optional CTA button" />
                </StepCardHeader>

                <StepCardContent>
                  <FormControl error={errors?.resources_call_to_action_button_display}>
                    <Checkbox
                      text="Include Call-to-Action Button"
                      {...register(`resources_call_to_action_button_display`)}
                    />
                  </FormControl>

                  <FormControl
                    error={errors?.resources_call_to_action_button_label}
                  >
                    <TextControl
                      {...register(`resources_call_to_action_button_label`, {
                        ...(isCTAButtonEnabled && {
                          required: 'Button text is required'
                        })
                      })}
                      placeholder="Enter Button Text"
                      disabled={!isCTAButtonEnabled}
                      maxLength={20}
                    />
                  </FormControl>

                  <FormControl
                    error={errors?.resources_call_to_action_button_url}
                  >
                    <TextControl
                      {...register(`resources_call_to_action_button_url`, {
                        ...(isCTAButtonEnabled && {
                          required: 'URL is required', // Required validation
                          pattern: {
                            value: /^(ftp|http|https):\/\/[^ "]+$/,
                            message: 'Please enter a valid URL' // URL validation
                          }
                        })
                      })}
                      disabled={!isCTAButtonEnabled}
                      placeholder="Enter URL"
                    />
                  </FormControl>
                </StepCardContent>
              </StepCard>
          }

          <SiteMakerActions renderButtons={renderButtons} />
        </form>
        <Dialog
          title="Add resource"
          open={showResourcesDialog}
        >
          <ResourceForm
            categories={resourceCategories}
            onSuccess={toggleShowResourcesDialog}
            onClose={toggleShowResourcesDialog}
          />
        </Dialog>
      </SiteMaker>
    </Page>
  );
};

export default ResourcesBlockConfig;
