import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DataTable, Dialog, IconButton, Loader, useDocumentTitle, usePageTitleHeader } from '@clatter/ui';
import { formatDateTime } from '@clatter/platform';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  deleteBioGroup,
  fetchBioGroups,
  selectAllBioGroups,
} from '../../../store/bio-groups.slice';
import BioGroupForm from './BioGroupForm';
import routes, { documentTitleMap } from "../../../routes/routes";

const bioGroupColumns = [
  {
    label: 'Group name',
    name: 'groupName',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Created by',
    name: 'c_created_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDateTime(row.createdAt),
    align: 'left',
    sortable: true,
  },
  {
    label: 'Updated by',
    name: 'c_updated_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date updated',
    name: 'updatedAt',
    customRender: (row) => formatDateTime(row?.updatedAt) || '',
    align: 'left',
    sortable: true,
  },
];

const BioGroups = () => {
  useDocumentTitle(documentTitleMap.adminBioGroups)
  const dispatch = useDispatch();
  const [editableItem, setEditableItem] = useState(null);

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: "Bio groups",
    linksType: 'msm-admin',
    routes: routes,
  });

  useEffect(() => {
    dispatch(fetchBioGroups());
  }, [dispatch]);

  const bioGroups = useSelector(selectAllBioGroups);

  const loading = useSelector(
    (state) => state.bioGroups.loadingStatus === 'loading',
  );

  const handleEditClick = (event) => {
    const clickedItemId = parseInt(event.currentTarget?.dataset?.id);

    setEditableItem(
      (clickedItemId && bioGroups.find((bio) => bio.id === clickedItemId)) ||
        {},
    );
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this group?')) {
      dispatch(deleteBioGroup(parseInt(event.currentTarget.dataset.id)));
    }
  };

  const isEdit = editableItem && 'groupName' in editableItem;

  return (
    <>
      {loading && <Loader />}

      { renderPageTitleHeader({
        headerActions: <Button onClick={handleEditClick}>Add group</Button>
      })}

      <div>
        <DataTable
          autofit
          columns={bioGroupColumns}
          defaultSortField="groupName"
          filterColumns={['groupName']}
          name="adminBioGroups"
          rows={bioGroups}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <Dialog
        title={isEdit ? 'Edit bio group' : 'Add bio group'}
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <BioGroupForm
          isEdit={isEdit}
          editableItem={editableItem}
          onSuccess={handleDismissEdit}
        />
      </Dialog>
    </>
  );
};

export default BioGroups;
