import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DataTable, Dialog, IconButton, Loader, useDocumentTitle, usePageTitleHeader } from '@clatter/ui';
import { formatDateTime } from '@clatter/platform';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  deleteVideoCategory,
  fetchVideoCategories,
  selectAllVideoCategories,
} from '../../../store/video-categories.slice';
import VideoCategoryForm from './VideoCategoryForm';
import routes, { documentTitleMap } from "../../../routes/routes";

const videoCategoryColumns = [
  {
    label: 'Category name',
    name: 'categoryName',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Created by',
    name: 'c_created_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDateTime(row.createdAt),
    align: 'left',
    sortable: true,
  },
  {
    label: 'Updated by',
    name: 'c_updated_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date updated',
    name: 'updatedAt',
    customRender: (row) => formatDateTime(row?.updatedAt) || '',
    align: 'left',
    sortable: true,
  },
];

const VideoCategories = () => {
  useDocumentTitle(documentTitleMap.adminVideoCategories)
  const dispatch = useDispatch();
  const [editableItem, setEditableItem] = useState(null);

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: "Video categories",
    linksType: 'msm-admin',
    routes: routes,
  });

  useEffect(() => {
    dispatch(fetchVideoCategories());
  }, [dispatch]);

  const videoCategories = useSelector(selectAllVideoCategories);

  const loading = useSelector(
    (state) => state.videoCategories.loadingStatus === 'loading',
  );

  const handleEditClick = (event) => {
    const clickedItemId = parseInt(event.currentTarget?.dataset?.id);

    setEditableItem(
      (clickedItemId &&
        videoCategories.find(
          (videoCategory) => videoCategory.id === clickedItemId,
        )) ||
        {},
    );
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this category?')) {
      dispatch(deleteVideoCategory(parseInt(event.currentTarget.dataset.id)));
    }
  };

  const isEdit = editableItem && 'categoryName' in editableItem;

  return (
    <>
      {loading && <Loader />}

      { renderPageTitleHeader({ headerActions: <Button onClick={handleEditClick}>Add category</Button> }) }

      <div>
        <DataTable
          autofit
          columns={videoCategoryColumns}
          defaultSortField="categoryName"
          filterColumns={['categoryName']}
          name="adminVideoCategories"
          rows={videoCategories}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <Dialog
        title={isEdit ? 'Edit video category' : 'Add video category'}
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <VideoCategoryForm
          isEdit={isEdit}
          editableItem={editableItem}
          onSuccess={handleDismissEdit}
        />
      </Dialog>
    </>
  );
};

export default VideoCategories;
