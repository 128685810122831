import React from 'react';
import { buttonWidths, DataGridPremium, IconButton } from '@clatter/ui';
import {
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';
import { formatDateTime, handleExportAsCSV } from '@clatter/platform';

export const getColumns = ({ extraColumns }) => [
  {
    headerName: 'Creator',
    field: 'created_by',
    valueGetter: ({ row }) => {
      if (!row?.created_by) {
        return null;
      }
      return `${row?.created_by?.first_name} ${row?.created_by?.last_name}`;
    },
    valueFormatter: (params) => params?.value || 'N/A',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Date Created',
    field: 'created_at',
    valueGetter: ({ row }) => row?.created_at,
    valueFormatter: ({ value }) => formatDateTime(value),
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Site',
    field: 'site',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Pages',
    field: 'pages',
    valueGetter: ({ row }) => row?.pages?.length,
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Published',
    field: 'published',
    valueGetter: ({ row }) => row?.published,
    valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
    sortable: true,
    flex: 1,
  },
  ...extraColumns,
];

const CustomToolbar = ({ exportFileName }) => {
  const apiRef = useGridApiContext();

  return (
    <GridToolbarContainer
      style={{
        justifyContent: 'end',
      }}
    >
      <IconButton
        tooltip="Export"
        width={buttonWidths.md}
        onClick={() =>
          handleExportAsCSV({
            apiRef: apiRef,
            exportFileName: exportFileName,
          })
        }
      >
        <CloudDownloadIcon />
      </IconButton>
    </GridToolbarContainer>
  );
};

const MicrositesDetailSiteTable = ({
  extraColumns = [],
  isLoading = false,
  rows = [],
  exportFileName,
  name = "report/msm-microsites-detail-site"
}) => (
  <DataGridPremium
    showVerticalSidebar
    loading={isLoading}
    dataGridProps={{
      components: {
        Toolbar: () => CustomToolbar({ exportFileName }),
      },
      getRowClassName: (params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd',
    }}
    name={name}
    columns={getColumns({ extraColumns })}
    defaultSortField={{ field: 'created_at', sort: 'desc' }}
    rows={rows}
    getRowId={(row) => row.uuid}
    disableColumnFilter
    disablePersist
  />
);

export default MicrositesDetailSiteTable;
