import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loader, Analytics, SsoCallbackPage, TrackPageviewEvent, LogoutPageView, ProtectedRoute } from '@clatter/ui';
import { sharedRoutes } from '@clatter/platform';
import Content from "../pages/Content/Content";
import MyMicrosites from '../pages/MyMicrosites/MyMicrosites';
import SiteSettings from '../pages/SiteSettings/SiteSettings';
import PageSettings from '../pages/PageSettings/PageSettings';
import PagePreview from '../pages/PagePreview/PagePreview';
import PreviewModal from '../pages/PreviewModal/PreviewModal';
import ComponentsList from '../pages/ComponentsList/ComponentsList';
import VideosBlockConfig from '../pages/VideosBlockConfig/VideosBlockConfig';
import ResourcesBlockConfig from '../pages/ResourcesBlockConfig/ResourcesBlockConfig';
import LinkResourcesBlockConfig from '../pages/ResourcesBlockConfig/LinkResourcesBlockConfig';
import BiosBlockConfig from '../pages/BiosBlockConfig/BiosBlockConfig';
import Publish from '../pages/Publish/Publish';
import FooterSettings from '../pages/FooterSettings/FooterSettings';
import AdditionalVideosBlock from '../pages/AdditionalVideosBlock/AdditionalVideosBlock';
import AnalyticsView from '../pages/Analytics/AnalyticsView';
import ReportPageviewsView from '../pages/Reports/Pageviews/PageViewsView';
import MicrositeStatisticsView from '../pages/Reports/MicrositeStatistics/MicrositeStatisticsView';
import ReportMicrositesDetailView from '../pages/Reports/MicrositesDetail/MicrositesDetailView';
import ReportMicrositesCreatedView from '../pages/Reports/MicrositeCreate/MicrositeCreateReportView';
import ReportMicrositesPageCreatedView from '../pages/Reports/MicrositePageCreate/MicrositePageCreateReportView';
import ExternalLinkBlock from '../pages/ExternalLinkBlock/ExternalLinkBlock';
import Admin from '../components/Admin/Admin';
import { userRolesMap } from '../constants';
import routes from './routes';
import LandingPageView from '../components/LandingPage/LandingPageView';
import OptionalCopyBlock from '../pages/OptionalCopyBlock/OptionalCopyBlock';
import PerformanceBlock from '../pages/Performance/PerformanceBlock';
import ChallengeBlock from '../pages/Challenge/ChallengeBlock';
import TestimonialsBlock from '../pages/Testimonials/TestimonialsBlock';
const AppRoutes = () => (
  <>
    <TrackPageviewEvent />
    <Analytics />

    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path={routes.index} element={<LandingPageView />} />
        <Route path={routes.logout} element={<LogoutPageView />} />
        <Route path={sharedRoutes.ssoCallback} element={<SsoCallbackPage />} />

        <Route
          path="/admin/*"
          element={
            <ProtectedRoute
              element={Admin}
              accessRoles={[userRolesMap.admin, userRolesMap.contentAdmin]}
            />
          }
        />

        <Route
          path={routes.microsites}
          element={
            <ProtectedRoute
              element={MyMicrosites}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.siteSettings}
          element={
            <ProtectedRoute
              element={SiteSettings}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageSettings}
          element={
            <ProtectedRoute
              element={PageSettings}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageContent}
          element={
            <ProtectedRoute
              element={Content}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageResourcesTable}
          element={
            <ProtectedRoute
              element={ResourcesBlockConfig}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageQuickLinks}
          element={
            <ProtectedRoute
              element={LinkResourcesBlockConfig}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageOptionalCopyBlock}
          element={
            <ProtectedRoute
              element={OptionalCopyBlock}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pagePerformance}
          element={
            <ProtectedRoute
              element={PerformanceBlock}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageTestimonials}
          element={
            <ProtectedRoute
              element={TestimonialsBlock}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageChallenge}
          element={
            <ProtectedRoute
              element={ChallengeBlock}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.publishSite}
          element={
            <ProtectedRoute
              element={Publish}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageResources}
          element={
            <ProtectedRoute
              element={ResourcesBlockConfig}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageVideos}
          element={
            <ProtectedRoute
              element={VideosBlockConfig}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageVideosTable}
          element={
            <ProtectedRoute
              element={AdditionalVideosBlock}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageBios}
          element={
            <ProtectedRoute
              element={BiosBlockConfig}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.externalLink}
          element={
            <ProtectedRoute
              element={ExternalLinkBlock}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageFooter}
          element={
            <ProtectedRoute
              element={FooterSettings}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pagePreview}
          element={
            <ProtectedRoute
              element={PagePreview}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.pageBuildPreview}
          element={
            <ProtectedRoute
              element={PreviewModal}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.components}
          element={
            <ProtectedRoute
              element={ComponentsList}
              accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.analytics}
          element={
            <ProtectedRoute
              element={AnalyticsView}
              accessRoles={[userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.reportsPageviews}
          element={
            <ProtectedRoute
              element={ReportPageviewsView}
              accessRoles={[userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.micrositeStatistics}
          element={
            <ProtectedRoute
              element={MicrositeStatisticsView}
              accessRoles={[userRolesMap.admin, userRolesMap.contentAdmin]}
            />
          }
        />

        <Route
          path={routes.reportsMicrositesDetail}
          element={
            <ProtectedRoute
              element={ReportMicrositesDetailView}
              accessRoles={[userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.reportsMicrositesCreate}
          element={
            <ProtectedRoute
              element={ReportMicrositesCreatedView}
              accessRoles={[userRolesMap.admin]}
            />
          }
        />

        <Route
          path={routes.reportsMicrositesPageCreate}
          element={
            <ProtectedRoute
              element={ReportMicrositesPageCreatedView}
              accessRoles={[userRolesMap.admin]}
            />
          }
        />
      </Routes>
    </Suspense>
  </>
);

export default AppRoutes;
