// @ts-nocheck
//@todo: fix types here
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { isRejected } from '@reduxjs/toolkit';
import { Button, buttonVariants, CircularLoader, Dialog, FormControl, SelectControl, TextControl } from '@clatter/ui';
import { requestStatus, useAuth, useDeepCompareEffect } from '@clatter/platform';
import { updateMicrosite } from '../../store';
import { selectMicrositesLoadingStatus } from '../../store/microsites.slice';
import { useGetMSMUsersOwnershipListQuery } from '../../services';

const StyledDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 40vw;
`;

const StyledFormContainer = styled.div`
  padding: 10px 0 30px;
  z-index: 10;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  .footer-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
`;

const strapiErrorNames = {
  VALIDATION_ERROR: 'ValidationError',
};

interface Microsite {
  id: number;
  name: string;
  owner: string;
}

interface OwnerSelectorOption {
  label: string;
  value: string;
}

interface Errors {
  [key: string]: {
    message: string;
  };
}

interface OwnershipTransferModalProps {
  opened: boolean;
  microsite: Microsite;
  onClose: ({ status, message }: { status?: string; message?: string }) => {};
}

export const OwnershipTransferModal: React.FC<OwnershipTransferModalProps> = ({ opened, microsite, onClose }) => {
  if (!opened) {
    return null;
  }

  if (!microsite?.id || !microsite?.name) {
    console.error('OwnershipTransferModal:: ERROR: invalid microsite data provided!');
    return null;
  }

  //region STATE
  const {
    data: usersOptions,
    error: usersOptionsError,
    isLoading: isOwnersLIstLoading,
  } = useGetMSMUsersOwnershipListQuery({});

  const dispatch = useDispatch();
  const { activeUser } = useAuth();
  const formContainerRef = useRef<HTMLDivElement>(null);

  const [selectedOwner, setSelectedOwner] = useState<OwnerSelectorOption>(null);
  const [errors, setErrors] = useState<Errors | null>(null);
  const [currentUser, setCurrentUser] = useState<OwnerSelectorOption>(null);

  const isSubmitDisabled = !selectedOwner || selectedOwner.value === microsite.owner;
  const isLoading = useSelector(selectMicrositesLoadingStatus) === requestStatus.pending || isOwnersLIstLoading;
  //endregion

  //region METHODS
  const handleClose = () => onClose(null);

  const handleReset = () => setSelectedOwner(null);

  const handleSubmit = async () => {
    const response = await dispatch(
      //@ts-ignore
      updateMicrosite({
        id: microsite.id,
        owner: selectedOwner.value,
        c_updated_by: activeUser.email,
      }),
    );

    if (isRejected(response)) {
      //@ts-ignore
      if (response?.payload?.error?.status === 404) {
        setErrors({ owner: { message: 'Microsite not found!' } });
        return;
      }

      //@ts-ignore
      if (response?.payload?.error?.name === strapiErrorNames.VALIDATION_ERROR) {
        setErrors({ owner: { message: 'Invalid value!' } });
        return;
      }

      onClose({
        status: 'error',
        message: `Error updating owner for microsite: "${microsite.name}"! Please contact the administrator.`,
      });
      return;
    }

    onClose({
      status: 'success',
      message: `Ownership for microsite: "${microsite.name}" has been successfully changed to: "${selectedOwner.label}".`,
    });
  };
  //endregion

  //region EFFECTS
  useDeepCompareEffect(() => {
    if (usersOptions?.length && !currentUser) {
      setCurrentUser(usersOptions.find((option) => option?.value === microsite?.owner));
    }
  }, [usersOptions, currentUser, microsite]);

  useEffect(() => {
    if (usersOptionsError) {
      //@ts-ignore
      onClose({ status: 'error', message: usersOptionsError?.message });
    }
  }, [usersOptionsError]);

  // This fix addresses glitches in "SelectControl" when it's
  // clicked for the first time after the initial render.
  // Although it should not be necessary, there is
  // currently a strange bug affecting its state
  // during the initial render.
  useEffect(() => {
    if (formContainerRef?.current) {
      formContainerRef.current.click();
    }
  }, [formContainerRef?.current]);
  //endregion

  return (
    //@ts-ignore
    <Dialog title={`Change Ownership for microsite: "${microsite?.name}"`} open={opened} fullTitle>
      <StyledDialogContainer ref={formContainerRef}>
        <StyledFormContainer>
          {/*@ts-ignore*/}
          <FormControl label="Current Owner">
            {/*@ts-ignore*/}
            <TextControl value={currentUser?.label || ''} disabled />
          </FormControl>

          {/*@ts-ignore*/}
          <FormControl label="New Owner" error={errors?.owner}>
            <SelectControl
              data-label="owner"
              placeholder="Select user"
              onChange={(newValue) => setSelectedOwner(newValue)}
              isDisabled={isLoading}
              options={usersOptions}
              value={selectedOwner}
            />
          </FormControl>
        </StyledFormContainer>

        <StyledFooter>
          <div>{isLoading && <CircularLoader />}</div>
          <div className="footer-actions">
            <Button
              testId="transfer_ownership_reset_button"
              type="reset"
              disabled={isSubmitDisabled || isLoading}
              variant={buttonVariants.clear}
              onClick={() => handleReset()}
            >
              Reset
            </Button>
            <Button
              testId="transfer_ownership_cancel_button"
              disabled={isLoading}
              variant={buttonVariants.clear}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>

            <Button
              testId="transfer_ownership_submit_button"
              type="submit"
              disabled={isSubmitDisabled || isLoading}
              variant={buttonVariants.primary}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>
        </StyledFooter>
      </StyledDialogContainer>
    </Dialog>
  );
};
