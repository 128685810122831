import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { generatePath, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isRejected } from '@reduxjs/toolkit';
import {
  Edit as EditIcon,
  Publish as PublishIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  TransferWithinAStation as TransferWithinAStationIcon
} from '@mui/icons-material';
import {
  DataGridPremium,
  IconButton,
  Button,
  TableSearchFilter,
  DataGridBulkEditButton,
  dataGridBulkEditButtonTypes,
  useDocumentTitle,
  usePageTitleHeader,
} from '@clatter/ui';
import {
  hasRole,
  useAuth,
  useNotices,
  noticesTypes,
  formatDateTime,
  useEventTracking,
  eventRequestTypes,
  useDeepCompareMemo,
  useDeepCompareEffect,
} from '@clatter/platform';
import { fetchAllMicrosites, setIsCreatingMicrosite } from '../../store';
import {
  cloneMicrosite,
  createMicrosite,
  deleteMicrosite,
  deleteMicrosites,
  selectAllMicrosites,
  selectMicrositesEntities,
} from '../../store/microsites.slice';
import Page from "../../components/Page/Page";
import { OwnershipTransferModal } from "../../components";
import { isSiteComplete } from '../../helpers';
import { pageFromStore } from '../fromStore';
import { userRolesMap } from '../../constants';
import micrositesApi from "../../api/microsites.api";
import routes, { documentTitleMap } from '../../routes/routes';


const StyledMyMicrosites = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  .my-microsites-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin: 0;
    }
  }
`;

const StyledMicrositeName = styled.div`
  cursor: pointer;
`;

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const MyMicrosites = () => {
  useDocumentTitle(documentTitleMap.microsites);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLoading } = useAuth();
  const { addNotice } = useNotices();
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const { trackEvent } = useEventTracking();

  const [ownershipTransferModalState, setOwnershipTransferModalState] = useState({
    opened: false,
    microsite: null,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userIsAdmin = useDeepCompareMemo(() => hasRole(userRolesMap.admin, user), [user, userRolesMap]);
  const userIsContentAdmin = useDeepCompareMemo(() => hasRole(userRolesMap.contentAdmin, user), [user, userRolesMap]);
  const enableOwnershipTransferAction = userIsAdmin;

  useEffect(() => {
    dispatch(fetchAllMicrosites({ user: user }));
    dispatch(setIsCreatingMicrosite(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const loading = useSelector((state) => state.microsites.loadingStatus === 'loading')

  const microsites = useSelector(selectAllMicrosites);
  const micrositesEntities = useSelector(selectMicrositesEntities);

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: documentTitleMap.microsites,
    routes: routes,
  });

  // @todo this useMemo is a recommended (by someone) technique (the MIT guys I think)
  // I'm not convinced that it's appropriate here, but I'm giving it a try.
  const micrositesMemo = React.useMemo(
    () =>
      microsites.map((microsite) => ({
        ...microsite,
        // @todo: microsite.pages is empty array which triggers error on "pageFromStore",
        //  also is unnecessary to map it as it is not used in the table
        pages: (microsite.pages || []).map(pageFromStore),
      })),
    [microsites],
  );

  const handleDeleteItem = async (event) => {
    if (window.confirm('Are you sure you want to remove this microsite?')) {

      const response = await dispatch( deleteMicrosite({ micrositeId: parseInt(event.currentTarget.dataset.id) }));

      if (isRejected(response)) {
        addNotice({
          message: response?.payload?.message || `Error deleting microsite "${micrositeToDelete.name}"`,
          type: 'error',
          title: 'Error',
        });
        return;
      }

      addNotice({
        message: response?.payload?.message || `Microsite has been successfully deleted`,
        type: 'success',
        title: 'Success',
      });
    }
  };

  const handleDeleteItems = (items, clearSelection) => async () => {
    if (
      window.confirm(`Are you sure you want to remove ${items.length} selected microsites?`)
    ) {
      const micrositesToDelete = items.map((micrositeId) =>
        micrositesMemo.find(({ id }) => id === micrositeId),
      );

      await dispatch(deleteMicrosites(micrositesToDelete));
      clearSelection();
    }
  };

  const handleCloneItem = async (event) => {
    const micrositeId = parseInt(event?.currentTarget?.dataset?.id);
    const micrositeToClone = micrositesEntities?.[micrositeId]

    const response = await dispatch(cloneMicrosite({ micrositeId: micrositeId }));

    if (isRejected(response)) {
      addNotice({
        message: response?.payload?.message || 'Error cloning microsite!',
        type: 'error',
        title: 'Error',
      });
      return;
    }

    addNotice({
      message: `Microsite "${micrositeToClone?.name}" has been successfully ` +
        `cloned${response?.payload?.name ? ` with name "${response.payload.name}."` : '.'}`,
      type: 'success',
      title: 'Success',
    });
  };

  const handlePublishItem = (event) => {
    navigate(generatePath(routes.publishSite, { siteId: parseInt(event.currentTarget.dataset.id) }));
  };

  const handleEditItem = (event) => {
    navigate(generatePath(routes.siteSettings, { siteId: parseInt(event.currentTarget.dataset.id) }));
  };

  const handleNavigateToPreview = async (micrositeId) => {

    if (!micrositeId) {
      const message = `Error handling preview. No microsite id provided!`
      console.error(message); // sentry support
      addNotice({
        message: message,
        type: 'error',
        title: 'Error',
      });
      return
    }

    try {
      const { data: { data: microsite } } = await micrositesApi.getMicrositeById({ micrositeId });

      if (!microsite) {
        const message = `Error handling preview. No microsite found with id ${micrositeId}!` // sentry support
        console.error(message);
        addNotice({
          message: message,
          type: 'error',
          title: 'Error',
        });
        return
      }

      const micrositeFirstPageName = microsite?.attributes?.pages?.data?.[0]?.attributes?.title

      if (!micrositeFirstPageName) {
        const message = 'Error handling preview. No microsite page name!'
        console.error(message); // sentry support
        addNotice({
          message: message,
          type: 'error',
          title: 'Error',
        });
        return
      }

      window.open(
        `${process.env.NX_PREVIEW_HOST}/api/preview?secret=MY_SECRET_TOKEN&slug=sites/${microsite?.attributes?.name}/${micrositeFirstPageName}`,
        '_blank',
        'noopener',
      );

    } catch (error) {
      const message = `Error handling preview. ${error?.message ? `Reason: ${error.message}` : ''}`
      console.error(message);
      addNotice({
        message: message,
        type: 'error',
        title: 'Error',
      });
    }
  };

  const handleNewClick = () => {
    dispatch(setIsCreatingMicrosite(true))
    dispatch(createMicrosite(user.email)).then((res) => {
      trackEvent(eventRequestTypes.micrositeCreated, {
        microsite_id: res.payload.id,
        microsite_name: res.payload.name,
      });

      navigate(generatePath(routes.siteSettings, { siteId: res.payload.id }));
    });
  };

  const handleRowClick = (item, event) => {
    // do not call history.push if we click on a link eg. status column
    if (event.target.nodeName !== 'A') {
      navigate(generatePath(routes.siteSettings, { siteId: item.row.id }));
    }
  };

  const micrositesColumns = useDeepCompareMemo(() => {
    let nextMicrositesColumns = [
      {
        field: 'name',
        headerName: 'Microsite Name',
        flex: 3,
        renderCell: (item) => (<StyledMicrositeName>{item.row.name}</StyledMicrositeName>),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 2,
        filterable: true,
        valueGetter: (item) => item.row.published,
        renderCell: (item) => {
          if ((!item.row.published || userIsContentAdmin) && isSiteComplete(item.row, item.row.pages)) {
            return <NavLink to={`/${item.id}/publish`}>{item.row.published ? 'Published' : 'Not Published'}</NavLink>;
          }

          return item.row.published ? 'Published' : 'Not Published';
        },
        filterOperators: [
          {
            label: 'Published',
            value: 'is',
            getApplyFilterFn: () => (params) => params.value,
          },
          {
            label: 'Not Published',
            value: 'isNot',
            getApplyFilterFn: () => (params) => !params.value,
          },
        ],
        sortComparator: (v1, v2, param1, param2) =>
          param1.api.getCellValue(param1.id, 'published') - param2.api.getCellValue(param2.id, 'published'),
      },
      {
        field: 'pagesCount',
        headerName: '# of Pages',
        flex: 1,
        type: 'number',
        align: 'left',
        filterable: true,
      },
      {
        field: 'createdAt',
        headerName: 'Date Created',
        flex: 2,
        type: 'date',
        filterable: true,
        valueFormatter: (params) => formatDateTime(params.value),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 2,
        sortable: false,
        disableExport: true,
        renderCell: (item) => (
            <>
              {
                enableOwnershipTransferAction &&
                <IconButton
                  onClick={() =>
                    setOwnershipTransferModalState({
                      opened: true,
                      microsite: item?.row,
                    })
                  }
                  tooltip="Transfer Ownership"
                >
                  <TransferWithinAStationIcon/>
                </IconButton>
              }
              <IconButton
                disabled={!item.row?.pagesCount > 0}
                data-id={item.id}
                onClick={() => handleNavigateToPreview( item.id)}
                tooltip="Preview"
              >
                <VisibilityOutlinedIcon />
              </IconButton>
              <IconButton
                data-id={item.id}
                onClick={handleEditItem}
                tooltip="Edit"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                disabled={item.row?.pagesCount <= 0}
                data-id={item.id}
                onClick={handlePublishItem}
                tooltip="Publish"
              >
                <PublishIcon />
              </IconButton>
              <IconButton
                data-id={item.id}
                onClick={handleCloneItem}
                tooltip="Clone"
              >
                <FileCopyOutlinedIcon />
              </IconButton>
              <IconButton
                data-id={item.id}
                onClick={handleDeleteItem}
                tooltip="Delete"
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </>
          ),
      },
    ];

    if (userIsAdmin) {
      nextMicrositesColumns = [
        ...nextMicrositesColumns.slice(0, 1),
        {
          field: 'owner',
          headerName: 'Owner',
          flex: 3,
        },
        ...nextMicrositesColumns.slice(1),
      ];
    }

    return nextMicrositesColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsContentAdmin, micrositesMemo, micrositesEntities]);

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = micrositesMemo.filter((row) =>
      micrositesColumns.some((col) => {
        if ('valueGetter' in col) {
          return searchRegex.test(col.valueGetter({ row: row }));
        }
        return searchRegex.test(row[col.field]);
      }),
    );
    setRows(filteredRows);
  };


  const handleOwnershipTransferModalClose = (data) => {
    // show corresponding notice after submit
    // skip if user just closed modal
    if (data && data?.status) {
      addNotice({
        message:  data?.message,
        type: data.status === 'success' ? noticesTypes.SUCCESS : noticesTypes.ERROR,
        title: 'Ownership Transfer',
      });
    }

    // clear state
    setOwnershipTransferModalState({ opened: false, microsite: null });
  }

  //region EFFECTS
  useEffect(() => {
    requestSearch(searchText);
  }, [searchText]);

  useDeepCompareEffect(() => {
    setRows(micrositesMemo);
  }, [micrositesMemo]);
  //endregion

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  return (
    <Page>
      <StyledMyMicrosites>
        {renderPageTitleHeader({
          headerActions: <Button onClick={handleNewClick} testId="new-microsite-button">New</Button>,
          secondaryContent:
            <>
              <p>
                Please select New to create a microsite or click the microsite
                name below to edit an existing microsite.
              </p>
              <TableSearchFilter value={searchText} onChange={setSearchText} />
            </>
        })}

        <DataGridPremium
          loading={loading}
          name="msm/microsites"
          onRowClick={handleRowClick}
          columns={micrositesColumns}
          defaultSortField={{ field: 'created_at', sort: 'desc' }}
          rows={rows}
        />
      </StyledMyMicrosites>

      {enableOwnershipTransferAction && (
        <OwnershipTransferModal
          opened={ownershipTransferModalState?.opened}
          microsite={ownershipTransferModalState?.microsite}
          onClose={(data) => handleOwnershipTransferModalClose(data)}
        />
      )}
    </Page>
  );
};

export default MyMicrosites;
