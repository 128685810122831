import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import 'react-responsive-modal/styles.css';
import styled from 'styled-components';
//import { Modal } from 'react-responsive-modal';
import { Dialog, AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import Page from "../../components/Page/Page";

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: 16,
    flex: 1,
  },
}));

const PreviewModal = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    navigate(-1);
  };

  let { pageId } = useParams();

  // parse params
  pageId = parseInt(pageId);

  // the gatsby development server serves the dynamic page previews
  // we configure the url using process.env.PREVIEW_SERVER
  // currently, we run 'ENABLE_GATSBY_REFRESH_ENDPOINT=1 gatsby develop --port 8001'
  // and export PREVIEW_SERVER=http://localhost:8001
  const PREVIEW_SERVER = process.env.REACT_APP_PREVIEW_SERVER;
  if (!PREVIEW_SERVER) {
    console.error('ERROR: PREVIEW_SERVER not set');
  }

  // @todo the link needs to go back where we came from
  return (
    <Page>
      <div>
        <Dialog fullScreen open={open} onClose={onCloseModal}>
          <AppBar className={classes.appBar} style={{ background: 'white' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={onCloseModal}
                aria-label="close"
              >
                <CloseIcon style={{ color: 'black' }} />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Close
              </Typography>
            </Toolbar>
          </AppBar>

          <iframe
            id="inlineFrameExample"
            title="Inline Frame Example"
            src={`${PREVIEW_SERVER}/page/${pageId}`}
            height="100%"
            width="100%"
          />
        </Dialog>
      </div>
    </Page>
  );
};

export default PreviewModal;
