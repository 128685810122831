import React, { useEffect } from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useAuth, useDeepCompareEffect, useIsComponentMounted } from '@clatter/platform';
import {
  fetchMicrosites,
  fetchPageTemplates,
  fetchPages,
  updatePage,
  fetchVideoEmbeds,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import {
  Button,
  FormControl,
  Loader,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  usePageTitleHeader,
} from '@clatter/ui';
import Page from "../../components/Page/Page";
import VideoPicker from '../../components/VideoPicker/VideoPicker';
import { pageFromStore } from '../fromStore';
import { videoTableColumns } from '../ComponentsList/mocks';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import { getNextPage, isSiteComplete } from '../../helpers';
import SiteMakerActions from '../../components/SiteMaker/SiteMakerActions';
import PreviewButton from '../../components/SiteMaker/PreviewButton';
import { selectAllVideoEmbeds } from '../../store/video-embeds.slice';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import routes from '../../routes/routes';
import useMsmPageTitle from "../../hooks/useMsmPageTitle";
import { selectPagesEntities } from '../../store/pages.slice';

const AdditionalVideosBlock = () => {
  // set page title for GA4
  useMsmPageTitle();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLoading } = useAuth();
  const isComponentMounted = useIsComponentMounted();
  let { siteId, pageId } = useParams();

  // parse params
  pageId = parseInt(pageId);

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    getValues,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await dispatch(
        updatePage({
          id: currentPage.id,
          ...formData,
          variables: '{}',
        }),
      );
    }
    navigate(getNextPage(currentMicrosite, currentMicrosite.pages));
  };

  const onPreviewClick = async () => {
    const formData = getValues();
    await dispatch(
      updatePage({
        id: currentPage.id,
        ...formData,
        variables: '{}',
      }),
    );
    // reset form "dirty" state so further "preview"
    // clicks will not trigger the page update
    reset(formData);
  };

  useEffect(() => {
    // not site/page dependent fetches
    dispatch(fetchPageTemplates());
    dispatch(fetchVideoEmbeds());

    if (!siteId) {
      return false;
    }

    (async () => {
      const microsites = await dispatch(fetchMicrosites({ user: user, micrositeId: siteId }));
      const micrositePagesIds = (microsites?.payload?.data?.[0]?.attributes?.pages?.data || []).map(page => page?.id);

      dispatch(fetchPages({ pagesIds: micrositePagesIds }));
    })();

    window.scrollTo(0, 0);
  }, [siteId, user]);

  const videosLoading = useSelector((state) => state.videoEmbeds.loadingStatus);

  const pageTemplatesLoading = useSelector(
    (state) => state.pageTemplates.loadingStatus,
  );

  const micrositesLoading = useSelector(
    (state) => state.microsites.loadingStatus,
  );

  const pagesLoading = useSelector((state) => state.pages.loadingStatus);

  const loading =
    videosLoading === 'loading' ||
    pageTemplatesLoading === 'loading' ||
    micrositesLoading === 'loading' ||
    pagesLoading === 'loading';

  const pageEntities = useSelector(selectPagesEntities);
  const pageTemplates = useSelector(selectAllPageTemplates);

  const videos = useSelector(selectAllVideoEmbeds);

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(siteId, page, pageTemplates, pageEntities[page.id]),
      ),
    };
  });

  const currentPage = pageEntities[pageId];

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentPage: currentPage,
    currentMicrosite: currentMicrosite,
    pageTitle: "Select Your Videos",
    routes: routes,
  });

  const redirectToPublish = () => {
    navigate(generatePath(routes.publishSite, { siteId: currentMicrosite.id }));
  };

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Videos Table and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite?.name}
        pageName={currentPage?.name}
        onPreviewClick={isDirty ? onPreviewClick : true}
      />
      <Button
        disabled={isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  useDeepCompareEffect(() => {
    if (isComponentMounted) {
      reset({
        video_embeds: currentPage?.video_embeds || [],
      });
    }
  }, [reset, currentPage?.video_embeds, isComponentMounted]);

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  return (
    <Page key={pageId}>
      {loading && <Loader />}

      <SiteMaker site={currentMicrosite}>

        { renderPageTitleHeader() }

        <SiteMakerContentHead
          subhead="Please select your videos. A preview of your selected videos will appear below."
          pageId={pageId}
          siteName={currentMicrosite?.name}
          pageName={currentPage?.name}
        />
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <StepCard>
            <StepCardHeader step={1}>
              <StepCardTitle text="Select videos" />
            </StepCardHeader>
            <StepCardContent>
              <FormControl noPadding error={errors.video_embeds}>
                <Controller
                  name="video_embeds"
                  rules={{
                    validate: (value) => {
                      if (!value || !value.length) {
                        return 'Field is required';
                      }

                      return true;
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <VideoPicker
                      onChange={onChange}
                      options={videos}
                      tableColumns={videoTableColumns}
                      value={value}
                    />
                  )}
                  control={control}
                  defaultValue={currentPage?.video_embeds || []}
                />
              </FormControl>
            </StepCardContent>
          </StepCard>
          <SiteMakerActions renderButtons={renderButtons} />
        </form>
      </SiteMaker>
    </Page>
  );
};

export default AdditionalVideosBlock;
