import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Loader, usePageTitleHeader } from '@clatter/ui';
import { requestStatus, useAuth } from '@clatter/platform';

import routes from '../../routes/routes';
import { pageFromStore } from '../fromStore';
import Page from '../../components/Page/Page';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import { selectPagesEntities } from '../../store/pages.slice';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import { fetchMicrosites, fetchPages, fetchPageTemplates } from '../../store';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import TestimonialsForm from "../../components/TestimonialsForm/TestimonialsForm";

export const defaultRailItem = {
  headline: '',
  description: '',
  showButton: false,
};

const railsConfig = {
  leftMaxItems: 5,
  leftMinItems: 2,
  centerMaxItems: 0,
  hideCenterRail: true,
  disableLeftHeadline: true,
  disableCenterHeadline: true,
  hideLeftCta: true,
  hideCenterCta: true,
  leftTitle: 'Enter "What your employees are saying" content',
};


const VARIABLES_BLOCK_NAME = 'testimonialsBlock';

const TestimonialsBlock = () => {
  const dispatch = useDispatch();
  const { user, isLoading } = useAuth();
  let { siteId, pageId } = useParams();

  pageId = parseInt(pageId);

  const loading = useSelector(
    ({ contentRails, pages, pageTemplates, microsites }) =>
      pages.loadingStatus === requestStatus.pending ||
      pageTemplates.loadingStatus === requestStatus.pending ||
      microsites.loadingStatus === requestStatus.pending,
  );

  const pageEntities = useSelector(selectPagesEntities);
  const pageTemplates = useSelector(selectAllPageTemplates);
  const currentPage = pageEntities[pageId];

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some((page) => page.id === pageId),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(siteId, page, pageTemplates, pageEntities[page.id]),
      ),
    };
  });

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentPage: currentPage,
    currentMicrosite: currentMicrosite,
    pageTitle: 'Add Testimonials',
    routes: routes,
  });

  const data = useMemo(() => {
    if (currentPage?.variables) {
      const variables = JSON.parse(currentPage?.variables);

      if (variables?.[VARIABLES_BLOCK_NAME] && Array.isArray(variables?.[VARIABLES_BLOCK_NAME])) {
        return variables?.[VARIABLES_BLOCK_NAME];
      }

      return [
        {
          enabled: true,
          headline: 'What your employees are saying',
          description: '',
          showButton: false,
          buttonText: '',
          buttonUrl: '',
          items: [
            { ...defaultRailItem, id: uuidv4() },
            { ...defaultRailItem, id: uuidv4() },
          ],
        },
      ];
    }
  }, [currentPage, pageId]);

  useEffect(() => {
    // not site/page dependent fetches
    dispatch(fetchPageTemplates());

    if (!siteId) {
      return false;
    }

    (async () => {
      const microsites = await dispatch(fetchMicrosites({ user: user, micrositeId: siteId }));
      const micrositePagesIds = (microsites?.payload?.data?.[0]?.attributes?.pages?.data || []).map((page) => page?.id);

      dispatch(fetchPages({ pagesIds: micrositePagesIds }));
    })();

    window.scrollTo(0, 0);
  }, [siteId, user]);

  return (
    <Page>
      {loading && <Loader />}
      <SiteMaker site={currentMicrosite}>
        {renderPageTitleHeader()}

        <SiteMakerContentHead subhead="Please complete the following steps and click the “continue” button below" />

        {currentPage && (
          <TestimonialsForm
            railsData={data}
            blockName={VARIABLES_BLOCK_NAME}
            currentMicrosite={currentMicrosite}
            currentPage={currentPage}
            pageId={pageId}
            railsConfig={railsConfig}
          />
        )}
      </SiteMaker>
    </Page>
  );
};

export default TestimonialsBlock;
