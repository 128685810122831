import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '@clatter/platform';
import { Loader, usePageTitleHeader } from '@clatter/ui';
import {
  fetchPages,
  fetchMicrosites,
  fetchPageTemplates,
  fetchBios,
} from '../../store';
import Page from "../../components/Page/Page";
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import { pageFromStore, bioFromStore } from '../fromStore';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import FooterSettingsForm from '../../components/FooterSettingsForm/FooterSettingsForm';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import { fetchMicrositesSettings } from '../../store/microsites-settings.slice';
import { selectPagesEntities } from '../../store/pages.slice';
import useMsmPageTitle from "../../hooks/useMsmPageTitle";
import routes from '../../routes/routes';

const FooterSettings = () => {
  // set page title for GA4
  useMsmPageTitle();

  const { user, isLoading } = useAuth();
  let { siteId, pageId } = useParams();

  // parse params
  pageId = parseInt(pageId);

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  const dispatch = useDispatch();
  useEffect(() => {
    // not site/page dependent fetches
    dispatch(fetchPageTemplates());
    dispatch(fetchBios());
    dispatch(fetchMicrositesSettings());

    if (!siteId) {
      return false;
    }

    (async () => {
      const microsites = await dispatch(fetchMicrosites({ user: user, micrositeId: siteId }));
      const micrositePagesIds = (microsites?.payload?.data?.[0]?.attributes?.pages?.data || []).map(page => page?.id);

      dispatch(fetchPages({ pagesIds: micrositePagesIds }));
    })();

    window.scrollTo(0, 0)
  }, [siteId, user]);

  const loading = useSelector(
    (state) =>
      state.pageTemplates.loadingStatus === 'loading' ||
      state.microsites.loadingStatus === 'loading' ||
      state.pages.loadingStatus === 'loading' ||
      state.bios.loadingStatus === 'loading' ||
      state.micrositesSettings.loadingStatus === 'loading',
  );

  const bios = useSelector((state) =>
    Object.values(state.bios.entities).map(bioFromStore),
  );

  const pageEntities = useSelector(selectPagesEntities);
  const pageTemplates = useSelector(selectAllPageTemplates);

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(siteId, page, pageTemplates, pageEntities[page.id]),
      ),
    };
  });

  const currentPage = currentMicrosite?.pages?.find(
    (page) => page?.id === pageId,
  );

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentPage: currentPage,
    currentMicrosite: currentMicrosite,
    pageTitle: "Customize the Footer",
    routes: routes,
  });

  const micrositesSettings = useSelector(
    ({ micrositesSettings }) => micrositesSettings.config,
  );

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  return (
    <Page key={pageId}>
      {loading && <Loader />}
      <SiteMaker site={currentMicrosite}>

        { renderPageTitleHeader() }

        <SiteMakerContentHead
          subhead="Select a client lead bio for your custom footer."
          pageId={pageId}
          siteName={currentMicrosite?.name}
          pageName={currentPage?.name}
        />
        <FooterSettingsForm
          micrositesSettings={micrositesSettings}
          currentMicrosite={currentMicrosite}
          currentPage={currentPage}
          bios={bios}
        />
      </SiteMaker>
    </Page>
  );
};

export default FooterSettings;
