import App from './app/App';
import { store } from './app/store';
import { renderApp } from '@clatter/ui';
import { initSentry, initTagManager, updateFavicon } from '@clatter/platform';

// this needs to be initialised per app,
// so it wouldn't work as a singleton
updateFavicon();
initTagManager();
initSentry();

renderApp(store, App);
