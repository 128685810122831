import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DataTable, Dialog, IconButton, Loader, useDocumentTitle, usePageTitleHeader } from '@clatter/ui';
import { formatDateTime } from '@clatter/platform';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  deleteResourceCategory,
  fetchResourceCategories,
  selectAllResourceCategories,
} from '../../../store/resource-categories.slice';
import ResourceCategoryForm from './ResourceCategoryForm';
import routes, { documentTitleMap } from "../../../routes/routes";

const resourceCategoryColumns = [
  {
    label: 'Category name',
    name: 'categoryName',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Created by',
    name: 'c_created_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDateTime(row.createdAt),
    align: 'left',
    sortable: true,
  },
  {
    label: 'Updated by',
    name: 'c_updated_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date updated',
    name: 'updatedAt',
    customRender: (row) => formatDateTime(row?.updatedAt) || '',
    align: 'left',
    sortable: true,
  },
];

const ResourceCategories = () => {
  useDocumentTitle(documentTitleMap.adminResourceCategories)
  const dispatch = useDispatch();
  const [editableItem, setEditableItem] = useState(null);

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: "Resource categories",
    linksType: 'msm-admin',
    routes: routes,
  });

  useEffect(() => {
    dispatch(fetchResourceCategories());
  }, [dispatch]);

  const resourceCategories = useSelector(selectAllResourceCategories);

  const loading = useSelector(
    (state) => state.resourceCategories.loadingStatus === 'loading',
  );

  const handleEditClick = (event) => {
    const clickedItemId = parseInt(event.currentTarget?.dataset?.id);

    setEditableItem(
      (clickedItemId &&
        resourceCategories.find(
          (resourceCategory) => resourceCategory.id === clickedItemId,
        )) ||
        {},
    );
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this category?')) {
      dispatch(deleteResourceCategory(parseInt(event.currentTarget.dataset.id)));
    }
  };

  const isEdit = editableItem && 'categoryName' in editableItem;

  return (
    <>
      {loading && <Loader />}

      { renderPageTitleHeader({ headerActions: <Button onClick={handleEditClick}>Add category</Button> }) }

      <div>
        <DataTable
          autofit
          columns={resourceCategoryColumns}
          defaultSortField="categoryName"
          filterColumns={['categoryName']}
          name="adminResourceCategories"
          rows={resourceCategories}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <Dialog
        title={isEdit ? 'Edit resource category' : 'Add resource category'}
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <ResourceCategoryForm
          isEdit={isEdit}
          editableItem={editableItem}
          onSuccess={handleDismissEdit}
        />
      </Dialog>
    </>
  );
};

export default ResourceCategories;
