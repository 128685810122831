import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, usePageTitleHeader } from '@clatter/ui';
import { requestStatus, useAuth } from '@clatter/platform';
import { ExternalLinkForm } from '../../components/ExternalLinkForm/ExternalLinkForm';
import { fetchMicrosites, fetchPages, fetchPageTemplates } from '../../store';
import { selectPagesEntities } from '../../store/pages.slice';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import { pageFromStore } from '../fromStore';
import routes from '../../routes/routes';
import Page from '../../components/Page/Page';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';

const ExternalLinkBlock = () => {
  const dispatch = useDispatch();
  const { user, isLoading: isAuthorizing } = useAuth();
  let { siteId, pageId } = useParams();

  const [saveButtonText, setSaveButtonText] = useState('Continue');

  // parse params
  pageId = parseInt(pageId);

  useEffect(() => {
    // not site/page dependent fetches
    dispatch(fetchPageTemplates());

    if (!siteId) {
      return false;
    }

    (async () => {
      const microsites = await dispatch(fetchMicrosites({ user: user, micrositeId: siteId }));
      const micrositePagesIds = (microsites?.payload?.data?.[0]?.attributes?.pages?.data || []).map(page => page?.id);

      dispatch(fetchPages({ pagesIds: micrositePagesIds }));
    })();

    window.scrollTo(0, 0);
  }, [siteId, user]);

  const loading = useSelector(
    ({ pages, pageTemplates, microsites }) =>
      pages.loadingStatus === requestStatus.pending ||
      pageTemplates.loadingStatus === requestStatus.pending ||
      microsites.loadingStatus === requestStatus.pending,
  );

  const pageEntities = useSelector(selectPagesEntities);
  const pageTemplates = useSelector(selectAllPageTemplates);
  const currentPage = pageEntities[pageId];

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some((page) => page.id === pageId),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(siteId, page, pageTemplates, pageEntities[page.id]),
      ),
    };
  });

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentPage: currentPage,
    currentMicrosite: currentMicrosite,
    pageTitle: 'Configure External Link',
    routes: routes,
  });

  //region METHODS
  const handleFormChange = (label) => {
    setSaveButtonText(label);
  };
  //endregion

  if (isAuthorizing) {
    return <div>Loading authorization...</div>;
  }

  if (!loading && (!currentMicrosite || !currentMicrosite.pages || !currentPage)) {
    return <p>Couldn't load current page</p>;
  }

  return (
    <Page key={pageId}>
      {loading && <Loader />}
      <SiteMaker site={currentMicrosite}>
        {renderPageTitleHeader()}

        <SiteMakerContentHead subhead={`Please provide external link data and click the “${saveButtonText}” button below`} />

        <ExternalLinkForm
          pageId={pageId}
          currentPage={currentPage}
          saveButtonText={saveButtonText}
          currentMicrosite={currentMicrosite}
          onChange={handleFormChange}
        />
      </SiteMaker>
    </Page>
  );
};

export default ExternalLinkBlock;
